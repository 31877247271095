<template>
  <base-modal-view title="Error" :message="getError()" @cancel="cancelClicked">
    <error-button v-on:click="cancelClicked" class="vertical-spacing-half auto-margin" title="Confirm" />
  </base-modal-view>
</template>

<script>
import ErrorButton from "../Buttons/ErrorButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "ErrorModal",
  components: { ErrorButton, BaseModalView },
  props: ["error", "error-dict"],
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    getError: function () {
      if (this.error) {
        if (this.error.response) {
          if (this.error.response.data?.error) {
            return this.error.response.data.error;
          } else if (this.error.response.data?.detail) {
            return this.error.response.data?.detail;
          } else if (typeof this.error.response.data === "object") {
            var errorMessage = "";
            for (const [key, value] of Object.entries(this.error.response.data)) {
              errorMessage += key;
              errorMessage += ": ";
              errorMessage += value;
              errorMessage += "\n";
            }
            return errorMessage;
          }
        }
        if (this.error.message) {
          return this.error.message;
        }
        return this.error;
      }
      if (this.errorDict) {
        if (this.errorDict.response?.data?.detail) {
          return this.errorDict.response?.data?.detail;
        } else if (this.errorDict.response?.data?.non_field_errors) {
          var error = "";
          this.errorDict.response?.data?.non_field_errors.forEach(error1 => {
            error += error1;
            error += " ";
          });
          return error;
        } else if (typeof this.errorDict === "string") {
          return this.errorDict;
        }
      }

      return "Something went wrong...";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";
@import "../../scss/filtering.scss";

input {
  text-align: center;
  font-size: 2em;
}
</style>
