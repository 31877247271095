<template>
  <div class="py-5 my-5" style="flex-column">
    <h1 v-if="success" class="m-auto p-5 filtering auto-margin small-max-width flex-column">
      Thank you for submitting your request, someone will be in touch with you soon!
    </h1>
    <form
      v-if="!success"
      @submit.prevent="onBookDemo"
      class="m-auto p-5 filtering auto-margin small-max-width flex-column"
    >
      <h3 class="mb-3 fw-bold">Book Demo</h3>
      <input type="text" class="input-field" placeholder="Name" v-model="form.name" required />
      <input type="text" class="input-field" placeholder="Phone Number" v-model="form.phone_number" required />
      <input type="text" class="input-field" placeholder="E-mail" v-model="form.email" required />
      <p style="text-align: center; font-size: 14px">All fields are required.</p>
      <primary-button class="w-100 py-4 mt-5 login-button" style="font-size: 18px" title="Request" type="submit" />
    </form>

    <error-modal v-if="error" :error-dict="error" @cancel="modalCancelled" />
  </div>
</template>

<script>
import PrimaryButton from "../components/Buttons/PrimaryButton.vue";
import ErrorModal from "../components/Modals/ErrorModal.vue";
import { mapActions } from "vuex";
import { bookDemo } from "../api/BookDemo.js";

export default {
  name: "BookDemo",
  components: { PrimaryButton, ErrorModal },
  data() {
    return {
      form: {
        name: "",
        phone_number: "",
        email: ""
      },
      success: false,
      error: null
    };
  },
  methods: {
    onBookDemo: function () {
      bookDemo(this.form)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          this.error = error;
        });
    },
    modalCancelled: function () {
      this.error = null;
    },
    ...mapActions({
      setAuthToken: "authStore/setAuthToken",
      setIsDealership: "authStore/setIsDealership",
      setCurrentDealership: "dealershipStore/setCurrentDealership",
      setCurrentReseller: "resellerStore/setCurrentReseller"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/filtering.scss";
@import "@/scss/_variables.scss";

.small-max-width {
  max-width: 600px;
}

h2 {
  font: normal 3.6em $FONT_SF_PRO_DISPLAY_BOLD;
  padding-top: 32px;
  padding-bottom: 32px;
}

input {
  margin-top: 18px;
  margin-bottom: 18px;
}

.login-button {
  text-align: center;
  margin-top: 18px;
}

.input-field {
  width: 100%;
  border: none;
  border-bottom: 1px solid #9ca0a5;
  font-size: 1em;
}
</style>
