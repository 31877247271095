<template>
  <transition name="fade" mode="out-in">
    <div ref="modalbg" class="flex-column modal-background" v-if="!hidden">
      <div
        id="content"
        :style="{ width: wide ? '95%' : medium ? '50%' : '500px' }"
        class="shadow flex-column"
        style="overflow: auto"
      >
        <div class="flex-row">
          <h1 class="vertical-spacing-half">{{ title }}</h1>
          <button v-on:click="cancelClicked()" class="flex-row-end">X</button>
        </div>
        <p class="message vertical-spacing-half" v-if="message">
          {{ message }}
        </p>
        <slot> </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BaseModalView",
  props: ["title", "message", "wide", "medium"],
  data: function () {
    return {
      hidden: true
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  mounted() {
    let _this = this;
    setTimeout(function () {
      _this.$nextTick(function () {
        _this.hidden = false;
      });
    }, 10);
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";
@import "../../scss/filtering.scss";

.modal-background {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#content {
  width: 500px;
  max-height: 90%;
  margin: auto;
  padding: $PADDING;
  display: flex;
  background-color: white;
  overflow: scroll;
  border-radius: 8px;

  @media only screen and (max-width: 500px) {
    max-width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    color: blue;
    font-size: 3em;
    cursor: pointer;
  }

  h1 {
    text-align: center;
    font-size: 2.4em;
    font-family: $FONT_SF_PRO_DISPLAY_SEMI_BOLD;
  }

  .message {
    font-size: 1.8em;
    text-align: center;
    font-family: $FONT_SF_PRO_DISPLAY_REGULAR;
  }
}
</style>
