<template>
  <button id="btn">
    <p style="margin: auto">
      {{ title }}
    </p>
  </button>
</template>

<script>
export default {
  name: "ErrorButton",
  props: ["title"]
};
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

#btn {
  display: flex;
  padding: $BUTTON_PADDING;
  color: white;
  background-color: $COLOUR_WARNING;
  font: normal 16px $FONT_SF_PRO_DISPLAY_SEMI_BOLD;
  border: 1px solid $COLOUR_WARNING;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    -webkit-text-stroke-width: $BUTTON_HOVER_STROKE_WIDTH;
  }
}
</style>
